/// I need this file to do two things
// 1. It needs to export a pop up that requires your password twice, and makes you click a box stating you've read the custom warning message before you can enter your pasword. twice :)
// 2. It needs to export a pop up that requires an admin or super, whenever they're denying something, to enter a denial message before they send the denial.

import React, { useState, useEffect, useContext } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import LangContext from '../context/LangContext';
import { Validator } from '../exportcomponents/ValidatorExports';
import "./styles.css";
import loadingDotGif from '../images/spinning-dot-loader.gif'

export const PassConfirm = ({ email, onFinishCallback }) => {
	const { langData } = useContext(LangContext);
	const [password, setPassword] = useState("");

	const verifyPass = async () => {
		if (password) {
			try{
				const verified = await axios.post(`${process.env.REACT_APP_API_URL}/account/login`, { email: email, password: password, validate: true });
			} catch (err) {
				return toast.error(langData.toast.incorrect_pass);
			}
		} else {
			return toast.error(langData.toast.please_enter_pass);
		}

		onFinishCallback();
	}

	return (
		<div className="form-background">
			<h1>{langData.popup_exports.confirm_delete}</h1>
			<input className="form-control mt-2" type="password" onChange={(e) => setPassword(e.target.value)} />
			<button className="btn btn-primary mt-3" onClick={verifyPass}>{langData.popup_exports.confirm}</button>
		</div>
	);
}

export const DenialMessage = ({ value, onFinishCallback }) => {
	const { langData } = useContext(LangContext);
	const [message, setMessage] = useState("");

	return (
		<div className="form-background m-5 bg-white">
			<strong>{langData.popup_exports.reason_denied}</strong>
			<textarea
				className="form-control mt-3"
				type="text"
				placeholder={langData.placeholders.reason_for_deny}
				onChange={(e) => setMessage(e.target.value)}
			/>
			<button className="btn btn-danger mt-3" onClick={() => onFinishCallback(value, message)}>{langData.popup_exports.confirm_denial}</button>
		</div>
	);
}

export const ApplicationQuestions = ({ questionList, job_id, onFinishCallback }) => {
	const { langData } = useContext(LangContext);
	const [cleared, setCleared] = useState(false);
	const [answers, setAnswers] = useState({});

	const onAnswerChange = (e) => {
		setAnswers({ ...answers, [e.target.name]: e.target.value });
		if (Object.keys(answers).length == questionList.length) {
			setCleared(true);
		}
		// console.log(answers);
	}

	return (
		<div className="form-background">
			<div className='mb-2 h5'>
				<strong>{langData.popup_exports.required_questions}</strong>
			</div>
			{questionList.map((question, i) => {
				return (
					<>
						<label className='mb-1'>{question.question}</label>
						<input
							className="form-control rounded"
							type="text"
							name={question.question}
							onChange={onAnswerChange}
						></input>
					</>
				)
			})}
			<button className="btn btn-primary mt-3" onClick={() => cleared && onFinishCallback(job_id, answers)}>{langData.popup_exports.apply}</button>
		</div>
	);
}

export const PaymentRedirect = ({ message, redirect_url, session_id }) => {
	// TODO: CTP SIGN UP
	const { langData } = useContext(LangContext);
	const stripe = useStripe();

	const onRedirectClick = async () => {
		if (redirect_url != null) {
			window.location.href = redirect_url
		} else {
			await stripe.redirectToCheckout({ sessionId: session_id });
		}
	}

	return (
		<div className="payment-background">
			<p>{message}</p>
			<button className="btn btn-primary mt-3" onClick={onRedirectClick}>{langData.popup_exports.proceed_to_payment}</button>
		</div>
	);
}

export const PaymentChanges = ({ account }) => {
	const { langData } = useContext(LangContext);
	const stripe = useStripe();

	useEffect(() => {
		// console.log(account)
	}, []);

	return (
		<div className="form-background">
			<div className="m-3">
			<strong>Select Renewal Frequency: </strong>
			<select className="form-select" aria-label="Default select example">
				<option selected>Renewal Frequency</option>
				<option value="1">One</option>
				<option value="2">Two</option>
				<option value="3">Three</option>
			</select>
			</div>
			<div className="m-3">
			<strong>Select Classification: </strong>
			<select className="form-select" aria-label="Default select example">
				<option selected>Classification</option>
				<option value="1">One</option>
				<option value="2">Two</option>
				<option value="3">Three</option>
			</select>
			</div>
			<button className="btn btn-primary">Submit</button>
		</div>
	);
}

export const EditProfileByAdmin = ({ userId, editorId }) => {
	const [form, setForm] = useState({});
    const { langData } = useContext(LangContext);
    const [userInfo, setUserInfo] = useState()
	const navigate = useNavigate()

    const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
		// console.log(form)
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            // console.log(form);
            if (form.email) {
                const email_is_unique = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-email-duplicate`, { email: form.email });
                // console.log(email_is_unique);
                if (email_is_unique.data) {
                    return toast.error(langData.toast.email_in_use);
                }
            } else {
                await axios.put(`${process.env.REACT_APP_API_URL}/account/super/${userId}`, form);
                toast.success(langData.toast.profile_changes_success);
				// navigate("/")
				if(editorId) {
					// navigate('/admin')
				} else {
					// navigate("/super")	
				}
            }

        } catch (err) {
            toast.error(err.message.data);
        }
    };

	async function asyncSetupEditProfileByAdmin() {
        const accountInfo = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${userId}`)

        setUserInfo(accountInfo.data)
	}

    useEffect(() => {
		asyncSetupEditProfileByAdmin()
    }, [])

    return (
        <div>
            <form className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
				<strong>Edit Users Profile</strong>
                {userInfo && <div className="form-group">
                    <label htmlFor="name">{langData.edit_profile.first_name}</label>
                    <input
                        type="first_name"
                        className="form-control"
                        name="first_name"
                        onChange={onChange}
                        placeholder={userInfo.first_name}
                    />
                </div>}
                {userInfo &&<div className="form-group">
                    <label htmlFor="name">{langData.edit_profile.last_name}</label>
                    <input
                        type="last_name"
                        className="form-control"
                        name="last_name"
                        onChange={onChange}
                        placeholder={userInfo.last_name}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="password">{langData.edit_profile.address_1}</label>
                    <input
                        type="address_1"
                        className="form-control"
                        name="address_1"
                        onChange={onChange}
                        placeholder={userInfo.address_1}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="password">{langData.edit_profile.address_2}</label>
                    <input
                        type="address_2"
                        className="form-control"
                        name="address_2"
                        onChange={onChange}
                        placeholder={userInfo.address_2}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="password">{langData.edit_profile.city}</label>
                    <input
                        type="city"
                        className="form-control"
                        name="city"
                        onChange={onChange}
                        placeholder={userInfo.city}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="password">{langData.edit_profile.state}</label>
                    <input
                        type="state"
                        className="form-control"
                        name="state"
                        onChange={onChange}
                        placeholder={userInfo.state}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="password">{langData.edit_profile.zip}</label>
                    <input
                        type="zip"
                        className="form-control"
                        name="zip"
                        onChange={onChange}
                        placeholder={userInfo.zip}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="displayName">{langData.edit_profile.language}</label>
                    <select
                        className="form-select"
                        name="language"
                        aria-label="Default select example"
                        onChange={onChange}
                    >
                        <option value="English">{langData.edit_profile.english}</option>
                        <option value="Spanish">{langData.edit_profile.spanish}</option>
                    </select>
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="credits_required">{langData.edit_profile.phone}</label>
                    <input
                        type="phone"
                        className="form-control"
                        name="phone"
                        onChange={onChange}
                        placeholder={userInfo.phone}
                    />
                </div>}
                {userInfo && <div className="form-group">
                    <label htmlFor="credits_required">{langData.edit_profile.email}</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={onChange}
                        placeholder={userInfo.email}
                    />
                </div>}
                <div>
                    <button onClick={submit} type="submit" className="btn btn-primary submit mt-2">
                        {langData.edit_profile.submit}
                    </button>
                </div>
            </form>
        </div>
    )
}

export const SendQuotePrice = ({ quote, questions, super_email, email }) => {
	const { langData } = useContext(LangContext);
	const [quotePrice, setQuotePrice] = useState({
		subscription_price: "2000",
		down_price: "1000",
		fitter_price: "45", 
		apprentice_price: "35",
		act_apprentice_price: "195",
		trainee_price: "35", 
		other_price: "25"
	});
	const [answers, setAnswers] = useState("");
	const [buttonDisable, setButtonDisable] = useState(false);
	const navigate = useNavigate();

	const onCostChange = (e) => {
		let updatedCosts = quotePrice;
		quotePrice[e.target.name] = e.target.value;
		// console.log(quotePrice)
		setQuotePrice(({ ...updatedCosts }));
		// console.log(updatedCosts);
	}

	const onEmailQuote = async (e) => {
		
		setButtonDisable(true);
    	setTimeout(() => {setButtonDisable(false)}, 3000);
		const quoteEmail = await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email`, {
			to: quote.email,
					subject: `Finish Onboarding ${quote.business_name} with ACTeducate.com`,
					header: `Congratulations, you're business (${quote.business_name}) has been registered with ACTeducate!`,
					body_1: `The pricing for your organization is as follows: `,
					italic_1: `These fees will be charged once you input your payment information using the link below)`,
					body_2: `Individual Fees charged at the time that the employees are approved by ACT to be added to your account: `,
					italic_2: `(These are monthly fees)`,
					footer: `Click Here to Finish the Onboarding Process: ${process.env.REACT_APP_VIEW_URL}/onboard?done=${quote.account_id}`,
					prices: {
						subscription_price: Math.round(quotePrice.subscription_price),
						down_price: Math.round(quotePrice.down_price),
						fitter_price: Math.round(quotePrice.fitter_price),
						apprentice_price: Math.round(quotePrice.apprentice_price),
						trainee_price: Math.round(quotePrice.trainee_price),
						other_price: Math.round(quotePrice.other_price),
						act_apprentice_price: Math.round(quotePrice.act_apprentice_price)
					}
		});

		try {
			await axios.put(`${process.env.REACT_APP_API_URL}/quote/${quote.id}`, {
				subscription_price: Math.round(quotePrice.subscription_price), 
				down_price: Math.round(quotePrice.down_price), 
				fitter_price: Math.round(quotePrice.fitter_price), 
				apprentice_price: Math.round(quotePrice.apprentice_price), 
				act_apprentice_price: Math.round(quotePrice.act_apprentice_price),
				trainee_price: Math.round(quotePrice.trainee_price), 
				other_price: Math.round(quotePrice.other_price)
			});
			
			// navigate('/')
			// navigate('/super')
			toast.success(langData.toast.quote_price_sent);
		} catch(err) {
			toast.error(err.message)
		}
	}

	return (
		<div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
			<strong>{langData.popup_exports.quote_price}</strong>{langData.popup_exports.billed_monthly}
			<div className="input-group mb-3">
			<strong>{langData.popup_exports.subscription_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="subscription_price"
							onChange={onCostChange} 
							defaultValue={"2000"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.down_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="down_price"
							onChange={onCostChange} 
							defaultValue={"1000"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.fitter_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="fitter_price"
							onChange={onCostChange} 
							defaultValue={"45"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.apprentice_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="apprentice_price"
							onChange={onCostChange}
							defaultValue={"35"} 
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.act_apprentice_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="act_apprentice_price"
							onChange={onCostChange} 
							defaultValue={"195"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.trainee_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="trainee_price"
							onChange={onCostChange} 
							defaultValue={"35"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.other_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="other_price"
							onChange={onCostChange}
							defaultValue={"25"} 
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				{/* <div className="form-group">
					<strong htmlFor="email">Billing Frequency</strong>
					<select
						name="billing_frequency"
						className="form-select"
						aria-label=".form-select-sm example">
						<option value="monthly" selected>Select Billing Frequency</option>
						<option value="monthly">Monthly</option>
						<option value="quarterly">Quarterly</option>
						<option value="biannually">Bi-Anually</option>
						<option value="annually">Anually</option>
					</select>
				</div> */}
			</div>
			<button
				className="btn btn-primary"
				onClick={onEmailQuote}
				disabled={buttonDisable}
			>
				{langData.popup_exports.email_quote}
			</button>
		</div>
	);
}

// TODO: Phase out custom business prices
export const SetOnboardPrice = ({ quote_id, session_id, redirect_message, redirect_later, redirectCallback, closeCallback, super_email }) => {
	const { langData } = useContext(LangContext);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [quotePrice, setQuotePrice] = useState({ 
		subscription_price: "2000", 
		down_price: "1000", 
		act_apprentice_price: "195", 
		apprentice_price: "35",
		fitter_price: "45",
		trainee_price: "35",
		other_price: "25"
	});

	const onCostChange = (e) => {
		let updatedCosts = quotePrice;
		quotePrice[e.target.name] = e.target.value // 01-2020;
		setQuotePrice({ ...updatedCosts });
		// console.log(updatedCosts);
	}

	const onSetPrice = async () => {
		setButtonDisable(true);
        setTimeout(() => {setButtonDisable(false)}, 3000);
		let minimum_met = true;

		for(const [key, value] of Object.entries(quotePrice)) {
			if(value < 5 && key != 'down_price') {
				minimum_met = false
				toast.error('All Prices require at least $5 (excluding down payment)')
				break
			}
		}

		if(minimum_met) {
			// TODO: CTP SIGN UP
			const quoteData = await axios.get(`${process.env.REACT_APP_API_URL}/quote/id/${quote_id}`);
			const { email, business_name } = quoteData.data
	
			console.log("redirect_later: ", redirect_later)
			if (redirect_later) {
				await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email/admin-onboard`, {
					to: email,
					business_name
				})
				// const quoteEmail = await axios.post(`${process.env.REACT_APP_API_URL}/account/send/email/admin-onboard`, {
				// 	to: quoteData.data.email,
				// 	subject: `Finish Onboarding ${quoteData.data.business_name} with ACTeducate.com`,
				// 	header: `Congratulations, you're business (${quoteData.data.business_name}) has been registered with ACTeducate!`,
				// 	body_1: `The pricing for your organization is as follows: `,
				// 	italic_1: `These fees will be charged once you input your payment information using the link below)`,
				// 	body_2: `Individual Fees charged at the time that the employees are approved by ACT to be added to your account: `,
				// 	italic_2: `(These are monthly fees)`,
				// 	footer: `Click Here to Finish the Onboarding Process: ${process.env.REACT_APP_VIEW_URL}/onboard?done=${quoteData.data.account_id}`,
				// 	prices: {
				// 		subscription_price: Math.round(quotePrice.subscription_price),
				// 		down_price: Math.round(quotePrice.down_price),
				// 		fitter_price: Math.round(quotePrice.fitter_price),
				// 		apprentice_price: Math.round(quotePrice.apprentice_price),
				// 		trainee_price: Math.round(quotePrice.trainee_price),
				// 		other_price: Math.round(quotePrice.other_price),
				// 		act_apprentice_price: Math.round(quotePrice.act_apprentice_price)
				// 	}
				// });
				toast.success(langData.toast.finish_sign_email_sent);
			}
	
			await axios.put(`${process.env.REACT_APP_API_URL}/quote/${quote_id}`, {
				subscription_price: Math.round(quotePrice.subscription_price), 
				down_price: Math.round(quotePrice.down_price), 
				fitter_price: Math.round(quotePrice.fitter_price), 
				apprentice_price: Math.round(quotePrice.apprentice_price),
				act_apprentice_price: Math.round(quotePrice.act_apprentice_price),
				trainee_price: Math.round(quotePrice.trainee_price), 
				other_price: Math.round(quotePrice.other_price)
			});
		}
	}

	useEffect(() => {
		// TODO: CTP SIGN UP
		console.log("quote_id", quote_id)
		console.log("session_id", session_id)
		console.log("redirect_later", redirect_later)
		if (!(quote_id && session_id && redirect_later !== undefined)) return console.log("Loading admin details")
 		setTimeout(() => onSetPrice()
			.then(() => {
				toast.success("Admin Configured Successfully")
				if (!redirect_later) return redirectCallback(session_id, redirect_message, redirect_later);
				closeCallback();
			})
			.catch((error) => {
				console.error("Admin Approve Error: ", error)
				toast.error('Something Went Wrong')
			})
		, 10)
	}, [quote_id && session_id && redirect_later])

	return (
		// <div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
		<div className="form-background d-flex flex-column align-items-center">
			<img className='h-25 w-25 mb-5' src={loadingDotGif}/>
			<i>Configuring Admin...</i>
			{/* <strong>{langData.popup_exports.quote_price}</strong>
			<div className="input-group mb-3">
			<strong>{langData.popup_exports.subscription_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="subscription_price"
							onChange={onCostChange}
							defaultValue={"2000"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.down_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="down_price"
							onChange={onCostChange}
							defaultValue={"1000"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.fitter_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="fitter_price"
							onChange={onCostChange} 
							defaultValue={"45"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.apprentice_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="apprentice_price"
							onChange={onCostChange} 
							defaultValue={"35"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.act_apprentice_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="act_apprentice_price"
							onChange={onCostChange}
							defaultValue={"195"} />
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.trainee_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="trainee_price"
							onChange={onCostChange} 
							defaultValue={"35"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
				<strong>{langData.popup_exports.other_cost}</strong>
				<div className="d-flex mb-3">
					<div className="input-group-prepend">
						<span className="input-group-text">$</span>
					</div>
					<div>
						<input
							type="text"
							className="form-control"
							aria-label="Amount (to the nearest dollar)"
							name="other_price"
							onChange={onCostChange} 
							defaultValue={"25"}
						/>
					</div>
					<div className="input-group-append">
						<span className="input-group-text">.00</span>
					</div>
				</div>
			</div>
			<button
				className="w-100 btn btn-outline-success"
				onClick={onSetPrice}
				disabled={buttonDisable}
			>
				{langData.popup_exports.set_price}
			</button> */}
		</div>
	);
}

export const UserInfo = ({ id }) => {
	const { langData } = useContext(LangContext);
	const [userInfo, setUserInfo] = useState({});

	async function asyncSetupUserInfo() {
		const quoteData = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${id}`);
		
		setUserInfo(quoteData.data)
	}

	useEffect(() => {
		asyncSetupUserInfo()
	}, []) 
		
	return (
		<div className="form-background extra-wide-popup">
			<div className='mb-5 d-flex justify-content-between align-items-center'>
				<div className='d-grid'>
					<span className='h3 my-0'>{userInfo.first_name}</span>
					<span className='h3 my-0'>{userInfo.last_name}</span>
				</div>
				<div className='ml-1'>
					<p className='text-end'><b>Contact Info</b></p>
					<p className='text-end'>{userInfo.phone}</p>
					<p className='text-end'>{userInfo.email}</p>
				</div>
			</div>
			<div className='d-flex justify-content-between align-items-start'>
				<table>
					<tbody>
						<tr>
							<td>
								<b>ID:</b>
							</td>
							<td className='text-end'>
								<span>{userInfo.shorthand}</span>
							</td>
						</tr>
						<tr>
							<td>
								<b>Classification:</b>
							</td>
							<td className='text-end'>
								<span>{userInfo.classification}</span>
							</td>
						</tr>
						<tr>
							<td>
								<b>Preferred Language:</b>
							</td>
							<td className='text-end'>
								<span>{userInfo.language}</span>
							</td>
						</tr>
						<tr>
							<td>
								<b>Date Joined:</b>
							</td>
							<td className='text-end'>
								<span>{userInfo.date_joined}</span>
							</td>
						</tr>
					</tbody>
				</table>
				<table>
					<tbody className='text-end'>
						<tr>
							<td>
								<b>Address</b>
							</td>
						</tr>
						{/* <tr><td>&nbsp;</td></tr> */}
						<tr>
							<td>
								<span>{userInfo.address_1}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{userInfo.address_2}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>{`${userInfo.city}, ${userInfo.state} ${userInfo.zip}`}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className='mt-3 d-block'>
				<b>Deactivation Notes:</b>
				<p>{userInfo.deactivation_note || 'No deactivation notes...'}</p>
			</div>
{/* 			
				{userInfo.shorthand ?
				(
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.id}</strong>
						<p>{userInfo.shorthand}</p>
					</div>
				) : ( null )
				}
				{userInfo.first_name || userInfo.last_name ?
				(
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.name}</strong>
						<p>{userInfo.first_name + " " + userInfo.last_name}</p>
					</div>
				) : ( null )
				}
				{userInfo.first_name || userInfo.last_name ?
					(
						<div className='d-flex justify-content-between my-1'>
							<strong>Email:</strong>
							<p>{userInfo.email}</p>
						</div>
					) : ( null )
				}
				{userInfo.phone ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.phone}</strong>
						<p>{userInfo.phone}</p>
					</div>
				) : (null)}
				{userInfo.classification ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.classification}</strong>
						<p>{userInfo.classification}</p>
					</div>
				) : (null)}
				{userInfo.language ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.language}</strong>
						<p>{userInfo.language}</p>
					</div>
				) : (null)}
				{userInfo.date_joined ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.data_applied}</strong>
						<p>{userInfo.date_joined}</p>
					</div>
				) : (null)}
				{/* {userInfo.status == true ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>Status:</strong>
						<p>{langData.popup_exports.active}</p>
					</div>
				) : (null)}
				{userInfo.status == false ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>Status:</strong>
						<p>{langData.popup_exports.inactive}</p>
					</div>
				) : (null)} 
				{userInfo.address_1 ? (
					<div className='d-flex justify-content-between my-1'>
						<strong>{langData.popup_exports.address}</strong>
						<div className='user-info-content'>
							<div>
								<p>{userInfo.address_1 + " "}</p>
								<p>{userInfo.address_2}</p> 
							</div>
							<div>
								{userInfo.city + ", " + userInfo.state + " " + userInfo.zip}
							</div>
						</div>
					</div>
				) : (null)} */}
				
		</div>
	)
}

export const CertList = ({ id }) => {
	const { langData } = useContext(LangContext);
	const [certList, setCertList] = useState([]);

	async function asyncSetupCertList() {
		const certData = await axios.get(`${process.env.REACT_APP_API_URL}/account/individual/cert/${id}`);
		
		setCertList(certData.data);
	}

	useEffect(() => {
		asyncSetupCertList()
	}, []) 
		
	return (
		<div className="form-background">
			{certList != "" ? (
				<div>
					<strong>{langData.popup_exports.certifications}</strong>
					{certList.map((cert, i) => {
					return (
					<div key={i}>
						<p>{cert}</p>
						</div>
					)
				})} 
				</div>
			) : (<strong>{langData.popup_exports.no_certifications}</strong>)}
					
		</div>
	)
}

export const Enrollments = ({ account_id, account }) => {
	const [enrollments, setEnrollments] = useState([]);
	const [userInfo, setUserInfo] = useState({});

	const fetchEnrollmentData = async () => {
		await axios.get(`${process.env.REACT_APP_API_URL}/absorb/enrollments/${account_id}`)
		.then(({data}) => {
            console.log(data)
			setEnrollments(data);
		}).catch((err) => {
			toast.error('Something went wrong loading the enrollments');
		});
	}

	const formatStatus = (status) => {
        console.log(status)
		if (status < 0) {
			return 'Not Enrolled';
		} else if (status == 0) {
			return 'Not Started';
		} else if (status < 3 && status > 0) {
			return 'In Progress';
		} else if (status == 3) {
			return 'Complete!';
		} else if (status == 4) {
			return 'Failed';
		} else return null
	}

	useEffect(() => {
		if (account != undefined) {
			fetchEnrollmentData();
			setUserInfo(account);
		}
	}, [account]);

	return (
		<div className="form-background wide-popup popup-scrollable">
			<h2 className="h2 mb-3">Enrollments</h2>
			<p className='mb-4'><b>Selected:</b> {userInfo.email}</p>
			<div className="enrollment user">
			</div>
			<div className='d-flex justify-content-between'>
				<b className='h5'>Course</b>
				<b className='h5'>Status</b>
			</div>
			<hr className="enrollment fixed-divider mb-1"/>
			{enrollments.length == 0 ? '\nLoading Courses...' : enrollments.map((course, i) => {
				if (course.Status < 4) return (
					<div>
						<div className='d-flex justify-content-between'>
							<span className="text-truncate">{course.CourseName}</span>
							<p className='text-nowrap ml-3'>{formatStatus(course.Status)}</p>
						</div>
						<hr className="enrollment divider mb-1"/>
					</div>
					// <div className="enrollment course-row">
					// 	<div>
					// 		<strong>Course:</strong>
					// 		<p>{course.CourseName}</p>
					// 	</div>
					// 	<div>
					// 		<strong>Status:</strong>
					// 		<p>{formatStatus(course.Status)}</p>
					// 	</div>
					// 	<hr className="enrollment divider"/>
					// </div>
				)
			})}
		</div>
	)
}

export const CertInfo = ({ certId, accountId }) => {
	const { langData } = useContext(LangContext);
	const [certInfo, setCertInfo] = useState([]);

	function format(inputDate) {
		var date = new Date(inputDate);
		if (!isNaN(date.getTime())) {
			// Months use 0 index.
			return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
		}
	}

	async function asyncSetupCertInfo() {
		const quoteData = await axios.get(`${process.env.REACT_APP_API_URL}/account/info/${certId}/${accountId}`);
		
		setCertInfo(quoteData.data)
	}

	useEffect(() => {
		asyncSetupCertInfo()
	}, []) 

	return (
	<div className="form-background">
		{certInfo[0] ? (
			<div style={{ overflowY: 'scroll', width: '100%', height: '500px' }}>
                    <div>
                        {certInfo[0].name && 
                            <div className="profile-info-field">
                                <p className="bold-header">
									{langData.popup_exports.lice_and_cert}
								</p>
                                <p className="sub-info">
                                    {certInfo[0].name}
                                </p>
                            </div>
                        }
                        {certInfo[0].reference_number &&
                            <div className="profile-info-field">
                                <p className="bold-header">
									{langData.popup_exports.ref_num}
								</p>
                                <p className="sub-info">
                                    {certInfo[0].reference_number}
                                </p>
                            </div>  
                        }
                        {certInfo[0].issued_date && 
                            <div className="profile-info-field">
                                <p className="bold-header">
									{langData.popup_exports.issued_date}
                                    </p>
                                <p className="sub-info">
                                    {format(certInfo[0].issued_date)}
                                </p>
                            </div>
                        }
                        {certInfo[0].rating && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.rating}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].rating}
                            </p>
                        </div>
                        }
                        {certInfo[0].state && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.state}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].state}
                            </p>
                        </div>
                        }
                        {certInfo[0].region && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.region}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].region}
                            </p>
                        </div>
                        }
                        {certInfo[0].notes && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.notes}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].notes}
                            </p>
                        </div>
                        }
                        {certInfo[0].trainer_name && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.trainer_name}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].trainer_name}
                            </p>
                        </div>
                        }
                        {certInfo[0].re_certification_date && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.renewal_date}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].re_certification_date}
                            </p>
                        </div>
                        }
                        {certInfo[0].employee_id && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.employee_id}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].employee_id}
                            </p>
                        </div>
                        }
                        {certInfo[0].eye_color && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.eye_color}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].eye_color}
                            </p>
                        </div>
                        }
                        {certInfo[0].hair_color && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.hair_color}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].hair_color}
                            </p>
                        </div>
                        }
                        {certInfo[0].weight && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.weight}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].weight}
                            </p>
                        </div>
                        }
                        {certInfo[0].height && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.height}
                            </p>
                            <p className="sub-info">
                                {certInfo[0].height}
                            </p>
                        </div>
                        }
                        {certInfo[0].birth_date && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.birth_date}
                                </p>
                            <p className="sub-info">
                                {format(certInfo[0].birth_date)}
                            </p>
                        </div>
                        }
                        {certInfo[0].license_number && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.license_number}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].license_number}
                            </p>
                        </div>
                        }
                        {certInfo[0].status && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.status}
                            </p>
                            <p className="sub-info">
                                {certInfo[0].status}
                            </p>
                        </div>
                        }
                        {certInfo[0].credits_required && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.credits_required}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].credits_required}
                            </p>
                            </div>
                        }
                        
                        {certInfo[0].classification && 
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.classification}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].classification}
                                </p>
                            </div>
                        }
                        {certInfo[0].renewal_frequency &&
                            <div className="profile-info-field">
                                <p className="bold-header">
									{langData.popup_exports.renewal_frequency}
                                    </p>
                                <p className="sub-info">
                                    {certInfo[0].renewal_frequency}
                                </p>
                            </div>
                        }
                        {certInfo[0].credits_earned &&
                            <div className="profile-info-field">
                            <p className="bold-header">
								{langData.popup_exports.credits_earned}
                                </p>
                            <p className="sub-info">
                                {certInfo[0].credits_earned}
                                </p>
                        </div>
                        }
                        {certInfo[0].type && 
                            <div className="profile-info-field">
                                <p className="bold-header">
									{langData.popup_exports.type}
                                </p>
                                <p className="sub-info">
                                    {certInfo[0].type}
                                </p>
                            </div>
                        }
                        {certInfo[0].expiration_date && 
                            <div className="profile-info-field">
                                <p className="bold-header">
								{langData.popup_exports.expiration}
                                </p>
                                <p className="sub-info">
                                    {format(certInfo[0].expiration_date)}
                                </p>
                            </div>
                        }
                        
                </div>
			</div>
			
		) : (null)
		}
          	                          
    </div>
	)
}

export const JobInfo = ({jobId, questions}) => {
	const [jobInfo, setJobInfo] = useState({});

	const fetchJobInfo = async () => {
		await axios.get(`${process.env.REACT_APP_API_URL}/job/jobs/${jobId}`)
		.then(({data}) => {

			// console.log(data);
			setJobInfo(data);
		}).catch((err) => {
			// console.log(err);
			toast.error('Something went wrong loading the job info');
		});
	}

	useEffect(() => {
		fetchJobInfo();
	}, []);

	return (
		<div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
			{Object.entries(jobInfo).map(([key,value]) => {
				return (
					<div>
						<strong>{key}</strong>
						{key !== 'questions' ? (
							<p>{(value || '').toString()}</p>
						) : (
							<div>
								{value.map((question, i) => {
									return (
										<p>{question.question}</p>
									)
								})}
							</div>
						)}
					</div>
				)
			})}
			{/* <button className="btn btn-primary">Questions</button> */}
		</div>
	)

}

export const SuperAddEmployee = ({}) => {
	const { langData } = useContext(LangContext);
	const [admins, setAdmins] = useState([]);
	const [adminChange, setAdminChange] = useState("");
	const [form, setForm] = useState({ language: "English", classification: 'fitter', billing_frequency: 'month' });
	
	const navigate = useNavigate()

	const onChange = (e) => {
        setForm({ ...form, admin_email: adminChange.admin_email, [e.target.name]: e.target.value });
        // console.log(e.target.name);
        // console.log(e.target.value);
    };

	const onAdminChange = async (e) => {
        const [valueOne, valueTwo] = String(e.target.value).split(',');
        setAdminChange({ ...adminChange, admin_email: valueOne, admin_id: valueTwo });
        // console.log(adminChange)
    };

	const submit = async (e) => {
        e.preventDefault();
        try {
            const valid = Validator(form, ['admin_email', 'email', 'classification', 'first_name', 'last_name', 'phone', 'address_1', 'city', 'state', 'zip', 'language', 'password', 'passwordCheck']);
			if(form.password == form.passwordCheck) {
				if (valid.length == 0) {
                    axios.post(`${process.env.REACT_APP_API_URL}/account/admin/individual`, { ...form, admin_id: adminChange.admin_id })
                    .then(res => {
						if (res.data.card_declined) return toast.error('Card Declined')
                        toast.success("Employee Added Successfully")
                    })
                    .catch(err => {
                        toast.error("An Error Occurred while Adding Employee")
                    })
					// navigate('/')
					// navigate('/super')
				} else {
					toast.error(langData.toast.please_enter_all_required);
				}
			} else {
                toast.error("Passwords Don't Match!");
            }
        } catch (err) {
            toast.error("Email Already Registered");
        }
    };

	async function asyncSetupSuperAddEmp() {
		const admin_list = await axios.get(`${process.env.REACT_APP_API_URL}/account/admins`)

		setAdmins(admin_list.data)
	}

	useEffect(() => {
		asyncSetupSuperAddEmp()
	}, []);

	return (
		<div className="form-background popup-scrollable">
			<h2 className="h2 mb-2">Add Fitter</h2>
            <div className="">
				<label htmlFor="email">Choose CTP</label>
				<select 
				className="mb-2" 
				name="admin" 
				aria-label="Default select example"
				onChange={onAdminChange}
				>
					<option>Choose CTP</option>
					{admins && admins.map((admin, i) => {
						return (
							<option key={i} value={[admin.admin_email, admin.id]}>{admin.business_name}</option>
						);
					})}
                </select>	
			</div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">Employee Email</label>
                        {!form.email ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        } 
                    </div>
                    <input
                        type="email"
                        className="mb-2"
                        name="email"
                        onChange={onChange}
                        placeholder="Enter Employee Email"
                    />
                </div>
                {/* <div className="">
                    <label htmlFor="email">{langData.sign_up_employee.account_type}</label>
                    <select
                        onChange={onChange}
                        name="classification"
                        className="mb-2"
                        aria-label=".mb-2-sm example">
                        <option selected>{langData.sign_up_employee.select_account_type}</option>
                        <option value="fitter">California Fire Fitter</option>
                        <option value="apprentice">California Fire Apprentice</option>
                        <option value="trainee">California Fire Trainee</option>
                        <option value="other">California Fire Other / Non-Fitter</option>
                    </select>
                </div> */}
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.first_name}</label>
                        {!form.first_name ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="first_name"
                        className="mb-2"
                        name="first_name"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_first_name}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.last_name}</label>
                        {!form.last_name ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="last_name"
                        className="mb-2"
                        name="last_name"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_last_name}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.phone}</label>
                        {!form.phone ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="phone"
                        className="mb-2"
                        name="phone"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_phone_number}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.address_1}</label>
                        {!form.address_1 ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="address_1"
                        className="mb-2"
                        name="address_1"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_address}
                    />
                </div>
                <div className="">
                    <label htmlFor="displayName">{langData.sign_up_employee.address_2}</label>
                    <input
                        type="address_2"
                        className="mb-2"
                        name="address_2"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_address_2}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.city}</label>
                        {!form.city ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="city"
                        className="mb-2"
                        name="city"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_city}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.state}</label>
                        {!form.state ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="state"
                        className="mb-2"
                        name="state"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_state}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.zip}</label>
                        {!form.zip ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="zip"
                        className="mb-2"
                        name="zip"
                        onChange={onChange}
                        placeholder={langData.placeholders.enter_zip}
                    />
                </div>
                <div className="">
                    <label htmlFor="displayName">{langData.sign_up_employee.language}</label>
                    <select
                        className="mb-2"
                        name="language"
                        aria-label="Default select example"
                        onChange={onChange}
                    >
                        <option value="English">{langData.sign_up_employee.english}</option>
                        <option value="Spanish">{langData.sign_up_employee.spanish}</option>
                    </select>
                </div>
                <div className="">
                    <label htmlFor="Billing Frequency">Billing Frequency</label>
                    <select
                        className="mb-2"
                        name="billing_frequency"
                        aria-label="Default select example"
                        onChange={onChange}
                    >
                        <option value="month">Monthly</option>
                        <option value="year">Annually</option>
                    </select>
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.password}</label>
                        {!form.password ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="password"
                        className="mb-2"
                        name="password"
                        onChange={onChange}
                        placeholder={langData.placeholders.password}
                    />
                </div>
                <div className="">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up_employee.password_2}</label>
                        {!form.passwordCheck ? 
                            <p className="float-right text-danger">{langData.sign_up_employee.required}</p>
                            : 
                            null
                        }
                        
                    </div>
                    <input
                        type="password"
                        className="mb-2"
                        name="passwordCheck"
                        onChange={onChange}
                        placeholder={langData.placeholders.password_2}
                    />
                </div>
                <div>
                    <button onClick={submit} type="submit" className="btn btn-primary submit mt-2 w-100">
                        {langData.sign_up_employee.submit}
                    </button>
                </div>
		</div>
	)

}

export const ViewQuotePricing = ({ id }) => {
	const { langData } = useContext(LangContext);
	const [quoteInfo, setQuoteInfo] = useState({});
	const [form, setForm] = useState({});
	
	const navigate = useNavigate()

	const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(form);
    };

	const submit = async (e) => {
        e.preventDefault();
        try {
			await axios.put(`${process.env.REACT_APP_API_URL}/quote/${quoteInfo.id}`, form)

			toast.success("Quote Pricing Changed")
			// navigate('/')
			// navigate('/super')
        } catch (err) {
            toast.error(err);
        }
    };

	async function setupViewQuotePricing() {
		const quote_list = await axios.get(`${process.env.REACT_APP_API_URL}/quote/account/${id}`)

		setQuoteInfo(quote_list.data)
	}

	useEffect(() => {
		setupViewQuotePricing()
	}, []);

	return (
		<div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
			<strong htmlFor="email">Quote Prices</strong>
			<hr className="absorb purchase-divider" />
            <div className="form-group">
				<strong>Subscription Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="subscription_price" 
                    onChange={onChange}
					placeholder={quoteInfo.subscription_price}
                />
			</div>
			<div className="form-group">
				<strong>Down Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="down_price" 
                    onChange={onChange}
					placeholder={quoteInfo.down_price}
                />
			</div>
			<div className="form-group">
				<strong>Fitter Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="fitter_price" 
                    onChange={onChange}
					placeholder={quoteInfo.fitter_price}
                />
			</div>
			<div className="form-group">
				<strong>Apprentice Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="apprentice_price" 
                    onChange={onChange}
					placeholder={quoteInfo.apprentice_price}
                />
			</div>
			<div className="form-group">
				<strong>Trainee Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="trainee_price" 
                    onChange={onChange}
					placeholder={quoteInfo.trainee_price}
                />
			</div>
			<div className="form-group">
				<strong>Other Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="other_price" 
                    onChange={onChange}
					placeholder={quoteInfo.other_price}
                />
			</div>
			<div className="form-group">
				<strong>ACT Apprentice Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="act_apprentice_price" 
                    onChange={onChange}
					placeholder={quoteInfo.act_apprentice_price}
                />
			</div>
            <div>
                <button onClick={submit} type="submit" className="btn btn-primary submit mt-2">
                    {langData.sign_up_employee.submit}
                </button>
            </div>
		</div>
	)
}

// TODO: Phase out custom business prices
export const EditQuotePricing = ({ id }) => {
	const { langData } = useContext(LangContext);
	const [quoteInfo, setQuoteInfo] = useState({});
	const [form, setForm] = useState({});
	
	const navigate = useNavigate()

	const onChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        // console.log(`${[e.target.name]}: ${e.target.value}`);
    };

	const submit = async (e) => {
        e.preventDefault();
        try {
			await axios.put(`${process.env.REACT_APP_API_URL}/account/quote/change/${quoteInfo.account_id}`, form)

			toast.success("Quote Pricing Changed")
			// navigate('/')
			// navigate('/super')
        } catch (err) {
            toast.error(err);
        }
    };

	async function asyncSetupEditQuote() {
		const quote_list = await axios.get(`${process.env.REACT_APP_API_URL}/quote/account/${id}?parse_prices=true`);

		setQuoteInfo(quote_list.data)
	}

	useEffect(() => {
		asyncSetupEditQuote()
	}, []);

	return (
		<div className="form-background" style={{ overflowY: 'scroll', height: '500px' }}>
			<strong htmlFor="email">Quote Prices</strong>
			<hr className="absorb purchase-divider" />
            <div className="form-group">
				<strong>Subscription Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="subscription_price" 
                    onChange={onChange}
					placeholder={quoteInfo.subscription_price}
                />
			</div>
			<div className="form-group">
				<strong>Down Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="down_price" 
                    onChange={onChange}
					placeholder={quoteInfo.down_price}
                />
			</div>
			<div className="form-group">
				<strong>Fitter Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="fitter_price" 
                    onChange={onChange}
					placeholder={quoteInfo.fitter_price}
                />
			</div>
			<div className="form-group">
				<strong>Apprentice Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="apprentice_price" 
                    onChange={onChange}
					placeholder={quoteInfo.apprentice_price}
                />
			</div>
			<div className="form-group">
				<strong>Trainee Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="trainee_price" 
                    onChange={onChange}
					placeholder={quoteInfo.trainee_price}
                />
			</div>
			<div className="form-group">
				<strong>Other Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="other_price" 
                    onChange={onChange}
					placeholder={quoteInfo.other_price}
                />
			</div>
			<div className="form-group">
				<strong>ACT Apprentice Price:</strong>
                <input
                	className="form-control mt-2" 
                    name="act_apprentice_price" 
                    onChange={onChange}
					placeholder={quoteInfo.act_apprentice_price}
                />
			</div>
            <div>
                <button onClick={submit} type="submit" className="btn btn-primary submit mt-2">
                    {langData.sign_up_employee.submit}
                </button>
            </div>
		</div>
	)

}

export default { 
	PaymentChanges, 
	PassConfirm, 
	DenialMessage, 
	ApplicationQuestions, 
	PaymentRedirect, 
	SendQuotePrice, 
	SetOnboardPrice, 
	CertList,
	CertInfo, 
	Enrollments, 
	JobInfo, 
	SuperAddEmployee, 
	ViewQuotePricing,
	EditQuotePricing,
};
